import '../styles/globals.css'
import type { AppProps } from 'next/app'
import * as React from "react";
import PagesHead from "../src/components/pages/PagesHead";

function StreamWiseAdminDashboardApp({ Component, pageProps }: AppProps) {
  // @ts-ignore
    return (
      <>
          <PagesHead />
        <
            // @ts-ignore
            Component {...pageProps} />
      </>
  )
}

export default StreamWiseAdminDashboardApp;
