import React from "react";
import Head from 'next/head'
import {NextSeo} from "next-seo";
import appVersion from "../../../public/version.json";

// https://www.freecodecamp.org/news/react-helmet-examples/
// https://nextjs.org/docs/pages/api-reference/components/head
// https://github.com/garmeeh/next-seo

export default function PagesHead({...props}) {

    const defaultTitle = process.env.APP_NAME;
    const titleTemplate = `%s | ${defaultTitle}`;
    return (
        <>
            <NextSeo
                // @ts-ignore
                noindex={true}
                // @ts-ignore
                nofollow={true}
                defaultTitle={defaultTitle}
                titleTemplate={titleTemplate}
                robotsProps={{
                    nosnippet: true,
                    notranslate: true,
                    noimageindex: true,
                    noarchive: true,
                    maxSnippet: -1,
                    maxImagePreview: 'none',
                    maxVideoPreview: -1,
                }}
                additionalLinkTags={[
                    {
                        rel: 'manifest',
                        href: '/manifest.json',
                    }
                ]}
                additionalMetaTags={[
                    {
                        name: 'generator',
                        content: `StreamWise Dashboard ${appVersion.appVersion}`,
                    },
                    {
                        name: 'application-name',
                        content: `${defaultTitle}`,
                    },
                    {
                        name: 'application-version',
                        content: `${appVersion.appVersion} - ${appVersion.buildVersion}`,
                    }
                ]}
                {...props}
            />
        </>
    )
}